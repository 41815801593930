<template>
  <v-container fluid>
    <v-row>
      <v-col sm="6" md="4" xl="3">
        <tl-setup-holds />
      </v-col>

      <v-col sm="6" md="4" xl="3">
        <tl-setup-groups />
      </v-col>

      <v-col sm="6" md="4" xl="3">
        <tl-setup-walls />
      </v-col>

      <v-col sm="6" md="4" xl="3">
        <tl-setup-setters />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Setter from '@/models/Setter'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import gymSettingsPageMixin from '../gym-settings-page.mixin'
import tlAutosaveMixin from '@/services/autosave/tl-auto-save.mixin'
import tlSaveBtnMixin from '@/components/shared/save-button.mixin'
import tlSetupSetters from './setters/tl-setup-setters'
import tlSetupHolds from './holds/tl-setup-holds'
import tlSetupWalls from './walls/tl-setup-walls'
import tlSetupGroups from './groups/tl-setup-groups'

export default {
  components: {
    tlSetupSetters,
    tlSetupHolds,
    tlSetupWalls,
    tlSetupGroups,
  },
  mixins: [toolbarMixin, gymSettingsPageMixin, tlAutosaveMixin, tlSaveBtnMixin],
  data: () => ({
    tlToolbarTitle: 'Gym setup',
  }),
  computed: {
    ...mapState(['gym']),
    tlToolbarButtons() {
      return [this.tlSaveBtn]
    },
    recordsToSave() {
      return [this.gym]
        .concat(this.gym.setters)
        .concat(this.gym.holds)
        .concat(this.gym.walls)
    },
  },
  created() {
    Setter.$apiAll({ params: { gym_id: this.gym.id } })
  },
}
</script>
