<template>
  <v-card>
    <v-img min-width="280px" height="200px" :src="require('./groups.jpg')" />

    <v-card-title class="d-flex flex-column align-start">
      <div>Groups</div>
      <span class="grey--text subtitle-1">Circuits in your gym</span>
    </v-card-title>

    <v-card-actions>
      <v-btn text :disabled="fetchingGroups" :loading="fetchingGroups" @click="toggleExpand">
        {{ expanded ? 'Hide' : 'Edit' }}
      </v-btn>
      <div class="flex-grow-1"></div>
      <v-btn icon :disabled="fetchingGroups" :loading="fetchingGroups" @click="toggleExpand">
        <v-icon>{{ expanded ? 'tl-chevron-up' : 'tl-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="expanded">
        <v-tabs v-if="isMultiType" v-model="climbTypeIndex" grow>
          <v-tab v-for="type in climbTypes" :key="type">{{ type == 'route' ? 'Climbing' : 'Bouldering' }}</v-tab>
        </v-tabs>

        <div v-if="!holdGroups.length && !tagGroups.length">
          <v-divider />
          <span class="d-flex justify-center ma-4">Nothing here yet</span>
        </div>

        <v-list v-if="holdGroups.length" subheader id="hold-groups-list">
          <v-divider />
          <v-subheader v-if="tagGroups.length">Circuits</v-subheader>
          <slick-list
            v-model="holdGroups"
            lock-axis="y"
            lock-offset="10%"
            use-drag-handle
            lock-to-container-edges
            append-to="#hold-groups-list"
            class="grey lighten-2"
            helper-class="elevation-4"
          >
            <tl-setup-group v-for="(group, index) in holdGroups" :key="group.id" :index="index" :group="group" />
          </slick-list>
        </v-list>

        <v-list v-if="tagGroups.length" subheader id="tag-groups-list">
          <v-divider />
          <v-subheader v-if="holdGroups.length">Custom groups</v-subheader>
          <slick-list
            v-model="tagGroups"
            lock-axis="y"
            lock-offset="10%"
            use-drag-handle
            lock-to-container-edges
            append-to="#tag-groups-list"
            class="grey lighten-2"
            helper-class="elevation-4"
          >
            <tl-setup-group v-for="(group, index) in tagGroups" :key="group.id" :index="index" :group="group" />
          </slick-list>
        </v-list>
        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn text :disabled="addingGroup" :loading="addingGroup" @click="addGroup">
            Add {{ isMultiType ? `${climbType}s ` : '' }}group
          </v-btn>
        </v-card-actions>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { SlickList } from 'vue-slicksort'
import Group from '@/models/Group'
import tlSetupGroup from './tl-setup-group'
import tlSetupGroupEdit from './tl-setup-group-edit'

export default {
  components: {
    tlSetupGroup,
    SlickList,
  },
  data: () => ({
    fetchingGroups: false,
    groupsFetched: false,
    expanded: false,
    addingGroup: false,
    climbTypes: ['boulder', 'route'],
    climbTypeIndex: 0,
  }),
  computed: {
    ...mapState(['gym']),
    isMultiType() {
      return this.gym.gym_type == 'climbing'
    },
    climbType() {
      return this.climbTypes[this.climbTypeIndex]
    },
    groups() {
      return this.gym.groups.filter(g => g.removed_at == null && g.climbs_type == this.climbType)
    },
    holdGroups: {
      get() {
        return this.groups.filter(group => group.hold_id).sort((a, b) => a.order - b.order)
      },
      set(newGroupsArr) {
        newGroupsArr.forEach((group, index) => {
          group.$update({ order: index })
          group.$apiSave()
        })
      },
    },
    tagGroups: {
      get() {
        return this.groups.filter(group => !group.hold_id).sort((a, b) => a.order - b.order)
      },
      set(newGroupsArr) {
        newGroupsArr.forEach((group, index) => {
          group.$update({ order: index + 1000 })
          group.$apiSave()
        })
      },
    },
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded
      if (this.expanded && !this.groupsFetched) this.fetchGroups()
    },
    fetchGroups() {
      this.fetchingGroups = true
      const filters = {
        gym_id: this.gym.id,
        score_system: 'none',
      }
      Group.$apiAll({ params: { json_params: { filters } } })
        .then(() => (this.groupsFetched = true))
        .finally(() => (this.fetchingGroups = false))
    },
    addGroup() {
      this.addingGroup = true
      Group.$apiCreate({
        gym_id: this.gym.id,
        climbs_type: this.climbType,
        live: true,
      })
        .then(newGroup => {
          this.$store.dispatch('dialog/open', {
            component: tlSetupGroupEdit,
            props: {
              group: newGroup,
              maxWidth: 800,
            },
          })
        })
        .finally(() => {
          this.addingGroup = false
        })
    },
  },
}
</script>
