<template>
  <v-card>
    <v-img min-width="280px" height="200px" :src="require('./walls.jpg')" />

    <v-card-title class="d-flex flex-column align-start">
      <div>Walls</div>
      <span class="grey--text subtitle-1">Change wall names</span>
    </v-card-title>

    <v-card-actions>
      <v-btn text @click="expanded = !expanded">
        {{ expanded ? 'Hide' : 'Edit' }}
      </v-btn>
      <div class="flex-grow-1"></div>
      <v-btn icon @click="expanded = !expanded">
        <v-icon>{{ expanded ? 'tl-chevron-up' : 'tl-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="expanded">
        <v-list dense id="walls-list">
          <slick-list
            v-model="walls"
            lock-axis="y"
            lock-offset="10%"
            use-drag-handle
            lock-to-container-edges
            append-to="#walls-list"
            class="grey lighten-2"
            helper-class="elevation-4"
          >
            <tl-setup-wall v-for="(wall, index) in walls" :key="wall.id" :index="index" :wall="wall" />
          </slick-list>
        </v-list>
        <v-divider />

        <v-card-actions v-if="user.admin">
          <v-btn text @click="addWall">Add wall</v-btn>
        </v-card-actions>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { SlickList } from 'vue-slicksort'
import Wall from '@/models/Wall'
import tlSetupWall from './tl-setup-wall'

export default {
  components: {
    tlSetupWall,
    SlickList,
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    ...mapState(['user', 'gym']),
    walls: {
      get() {
        return this.gym.walls.filter(wall => wall.removed_at == null).sort((a, b) => a.order - b.order)
      },
      set(newWallsArr) {
        newWallsArr.forEach((wall, index) => wall.$update({ order: index }))
      },
    },
  },
  methods: {
    addWall() {
      const number = this.walls.length + 1
      const wallProps = {
        gym_id: this.gym.id,
        name: number,
        floorplan_id: number,
        order: 100,
      }
      Wall.$apiCreate(wallProps, { eagerInject: true })
    },
  },
}
</script>
