<template>
  <v-card>
    <v-card-title>Opening hours</v-card-title>
    <v-card-text>
      <table>
        <tr>
          <th></th>
          <th></th>
          <th v-for="itemName in orderedItems" :key="itemName">
            <v-text-field :value="hours.items[itemName]" placeholder="Column name" @input="setItem(itemName, $event)" />
          </th>
        </tr>
        <tr v-for="day in orderedDays" :key="day">
          <td class="text-no-wrap">{{ day }}</td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-switch v-model="hours.days[day].closed" color="primary" v-on="on" @input="setClosed(day, $event)" />
              </template>
              <span>Toggle closed</span>
            </v-tooltip>
          </td>
          <td v-if="hours.days[day].closed" colspan="4">Closed</td>
          <td v-else v-for="(val, itemName) in hours.days[day].items" :key="itemName">
            <v-text-field
              :value="hours.days[day].items[itemName]"
              placeholder="Time"
              @input="setTime(day, itemName, $event)"
            />
          </td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    orderedDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    orderedItems: [1, 2, 3, 4].map(i => `item${i}`),
    hours: {},
  }),
  computed: {
    ...mapState(['gym']),
    defaultHours() {
      const items = { item1: null, item2: null, item3: null, item4: null }
      const days = this.orderedDays.reduce((acc, d) => Object.assign(acc, { [d]: { closed: true, items } }), {})
      return { version: 1, items, days }
    },
    gymHours() {
      return this.gym.opening_hours
    },
    hoursJSON() {
      return JSON.stringify(this.hours)
    },
  },
  created() {
    if (!this.gymHours || this.gymHours.version != this.defaultHours.version) {
      this.gym.$update({ opening_hours: this.defaultHours })
    }
    this.updateHours()
  },
  watch: {
    hoursJSON() {
      this.gym.$update({ opening_hours: this.hours })
      this.updateHours()
    },
  },
  methods: {
    setItem(name, value) {
      this.hours.items[name] = value
    },
    setClosed(day, closed) {
      this.hours.days[day].closed = closed
    },
    setTime(day, item, time) {
      this.hours.days[day].items[item] = time
    },
    updateHours() {
      this.hours = JSON.parse(JSON.stringify(this.gymHours))
    },
  },
}
</script>
