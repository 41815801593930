<template>
  <v-card>
    <v-img min-width="280px" height="200px" :src="require('./holds.jpg')" />

    <v-card-title class="d-flex flex-column align-start">
      <div>Holds</div>
      <span class="grey--text subtitle-1">Colors available in your gym</span>
    </v-card-title>

    <v-card-actions>
      <v-btn text @click="expanded = !expanded">
        {{ expanded ? 'Hide' : 'Edit' }}
      </v-btn>
      <div class="flex-grow-1"></div>
      <v-btn icon @click="expanded = !expanded">
        <v-icon>{{ expanded ? 'tl-chevron-up' : 'tl-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="expanded">
        <v-list id="holds-list">
          <slick-list
            v-model="holds"
            lock-axis="y"
            lock-offset="10%"
            use-drag-handle
            lock-to-container-edges
            append-to="#holds-list"
            class="grey lighten-2"
            helper-class="elevation-4"
          >
            <tl-setup-hold v-for="(hold, index) in holds" :key="hold.id" :index="index" :hold="hold" />
          </slick-list>
        </v-list>
        <v-divider />

        <v-card-actions>
          <v-btn text :disabled="addingHold" :loading="addingHold" @click="addHold">Add hold</v-btn>
        </v-card-actions>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { SlickList } from 'vue-slicksort'
import Hold from '@/models/Hold'
import tlSetupHold from './tl-setup-hold'

export default {
  components: {
    tlSetupHold,
    SlickList,
  },
  data: () => ({
    expanded: false,
    addingHold: false,
  }),
  computed: {
    ...mapState(['gym']),
    holds: {
      get() {
        return this.gym.holds.filter(hold => hold.removed_at == null).sort((a, b) => a.order - b.order)
      },
      set(newHoldsArr) {
        newHoldsArr.forEach((hold, index) => hold.$update({ order: index }))
      },
    },
  },
  methods: {
    addHold() {
      this.addingHold = true
      Hold.$apiCreate({
        gym_id: this.gym.id,
        color: '#E60000',
      }).finally(() => {
        this.addingHold = false
      })
    },
  },
}
</script>
