<template>
  <v-list-item class="white" two-line>
    <tl-list-item-avatar :avatar="setter.user && setter.user.avatar" />

    <v-list-item-content>
      <v-list-item-title>{{ setter.name }}</v-list-item-title>
      <v-list-item-subtitle class="d-flex flex-align-center">
        {{ role }}
        <v-icon small v-if="roleIcon" :color="setter.admin ? 'primary' : ''">{{ roleIcon }}</v-icon>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn icon @click="openEditor">
        <v-icon>tl-edit</v-icon>
      </v-btn>
    </v-list-item-action>

    <v-list-item-action>
      <v-btn icon :disabled="removing" :loading="removing" @click="remove">
        <v-icon>tl-delete</v-icon>
      </v-btn>
    </v-list-item-action>

    <v-list-item-action class="ml-2" style="cursor: row-resize">
      <v-icon v-handle>tl-drag-vertical</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { ElementMixin, HandleDirective } from 'vue-slicksort'
import Setter from '@/models/Setter'
import tlListItemAvatar from '@/components/shared/tl-list-item-avatar'
import tlSetupSetterEdit from './tl-setup-setter-edit'

export default {
  mixins: [ElementMixin],
  directives: {
    handle: HandleDirective,
  },
  components: {
    tlListItemAvatar,
  },
  props: {
    setter: { type: Setter, default: () => {} },
  },
  data: () => ({
    editing: false,
    removing: false,
  }),
  computed: {
    role() {
      if (this.setter.admin) return 'Gym admin'
      if (this.setter.linked) return 'Admin setter'
      if (this.setter.setter) return 'Setter'
      return ''
    },
    roleIcon() {
      if (this.setter.admin) return 'tl-shield-check'
      if (this.setter.linked) return 'tl-shield'
      return ''
    },
  },
  methods: {
    openEditor() {
      let props = {
        setter: this.setter,
        maxWidth: 800,
      }
      this.$store.dispatch('dialog/open', { component: tlSetupSetterEdit, props })
    },
    async remove() {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        title: `Remove setter`,
        text: 'Are you sure?',
      })
      if (!confirmed) return

      this.removing = true
      this.setter
        .$apiUpdate({ removed_at: new Date() })
        .then(() => {
          this.$store.dispatch('toast/success', 'Setter removed')
        })
        .finally(() => {
          this.removing = false
        })
    },
  },
}
</script>
