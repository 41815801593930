<template>
  <v-card>
    <v-card-title class="headline">Hold color</v-card-title>
    <v-divider />

    <v-card-text>
      <v-row justify="center" align="start">
        <v-col>
          <v-color-picker class="mx-auto" v-model="newColor" />
        </v-col>
        <v-col align="center">
          <v-color-picker v-if="newColorSecondary" v-model="newColorSecondary" class="mx-auto" />
          <v-btn outlined @click="toggleSecondary()" class="ma-4">
            {{ newColorSecondary ? 'Remove' : 'Add' }} secondary color
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col align="center">
          <div class="title text--primary mb-4">Preview</div>
          <tl-grade-circle size="100" :color="newColor" :color-secondary="newColorSecondary" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer />
      <v-btn text @click="close">Cancel</v-btn>
      <v-btn text color="primary" @click="apply">Apply</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import tlGradeCircle from '@/components/gym/climbs/tl-grade-circle'

export default {
  components: {
    tlGradeCircle,
  },
  props: {
    color: { type: String, default: '#E60000' },
    colorSecondary: { type: String, default: null },
    onApply: { type: Function, default: () => {} },
  },
  data: () => ({
    newColor: null,
    newColorSecondary: null,
  }),
  created() {
    this.newColor = this.color
    this.newColorSecondary = this.colorSecondary
  },
  methods: {
    ...mapActions('dialog', ['close']),
    toggleSecondary() {
      if (this.newColorSecondary) {
        this.newColorSecondary = null
      } else {
        this.newColorSecondary = '#E60000'
      }
    },
    apply() {
      this.onApply(this.newColor, this.newColorSecondary)
      this.close()
    },
  },
}
</script>
