<template>
  <v-card>
    <v-card-title class="headline">Edit group</v-card-title>
    <v-divider class="mb-4" />

    <v-card-text>
      <v-text-field :value="group.name" label="Name" @input="setGroupProp('name', $event)" />
      <v-text-field :value="group.description" label="Description" @input="setGroupProp('description', $event)" />

      <span>Group color</span>
      <v-radio-group v-model="colorType" class="my-0">
        <v-radio label="None" value="none" color="primary" />
        <v-radio v-if="selectableHolds.length" label="Use hold color" value="hold" color="primary" />
        <v-radio label="Custom tag color" value="custom" color="primary" />
      </v-radio-group>

      <v-expand-transition>
        <v-card-text v-if="colorType == 'hold'">
          <tl-selectable-color
            :value="group.hold_id"
            :holds="selectableHolds"
            required
            @input="setGroupProp('hold_id', $event)"
          />
        </v-card-text>
      </v-expand-transition>

      <v-expand-transition>
        <v-card-text v-if="colorType == 'custom'">
          <v-color-picker :value="group.color" @input="setGroupProp('color', $event)" />
        </v-card-text>
      </v-expand-transition>

      <v-switch
        v-model="adminOnly"
        color="primary"
        hint="Enable if this group is just to organize climbs internally"
        persistent-hint
      >
        <template v-slot:label>
          Admin only
          <v-icon class="ml-2" small v-if="!group.live">tl-shield</v-icon>
        </template>
      </v-switch>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer />
      <v-btn text @click="cancel">Cancel</v-btn>
      <v-btn text color="primary" :disabled="saving" :loading="saving" @click="apply">Apply</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import errorService from '@/services/error-service'
import Group from '@/models/Group'
import tlSelectableColor from '@/components/gym/climbs/shared/tl-selectable-color'

export default {
  components: {
    tlSelectableColor,
  },
  props: {
    group: { type: Group, default: null },
  },
  data: () => ({
    saving: false,
    colorType: 'none',
    initHoldId: null,
    initHoldColor: '#FF0000',
  }),
  computed: {
    ...mapState(['gym']),
    adminOnly: {
      get() {
        return !this.group.live
      },
      set(newVal) {
        this.setGroupProp('live', !newVal)
      },
    },
    selectableHolds() {
      return this.gym.holds.slice().sort((a, b) => a.order - b.order)
    },
  },
  created() {
    this.initHoldId = this.group.hold_id || this.selectableHolds[0].id
    this.initHoldColor = this.group.color || '#FF0000'
    if (this.group.hold_id) {
      this.colorType = 'hold'
    } else if (this.group.color) {
      this.colorType = 'custom'
    } else {
      this.colorType = 'none'
    }
  },
  watch: {
    colorType(newType) {
      this.setGroupProp('hold_id', null)
      this.setGroupProp('color', null)
      if (newType == 'hold') {
        if (!this.selectableHolds.length) return (newType = 'none')
        this.setGroupProp('hold_id', this.initHoldId)
      } else if (newType == 'custom') {
        this.setGroupProp('color', this.initHoldColor)
      }
    },
  },
  methods: {
    ...mapActions('dialog', ['close']),
    setGroupProp(prop, val) {
      this.group.$update({ [prop]: val })
    },
    cancel() {
      this.group.$restore()
      this.close()
    },
    apply() {
      this.saving = true
      this.group
        .$apiSave()
        .then(() => {
          this.$store.dispatch('toast/success', 'Group updated')
          this.close()
        })
        .catch(errorService.toast)
        .finally(() => (this.saving = false))
    },
  },
}
</script>
