<template>
  <v-list-item @click="toggleProp" :ripple="isSwitch">
    <v-list-item-avatar v-if="option.icon">
      <v-icon class="grey lighten-1 white--text" v-text="option.icon" />
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>{{ option.title }}</v-list-item-title>
      <v-list-item-subtitle style="white-space: normal">{{ option.subtitle }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action style="max-width: 50px">
      <v-switch v-if="isSwitch" v-model="val" color="primary" @click.prevent="" />
      <v-text-field v-if="isNumber" :value="val" type="number" @change="setProp" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    option: { type: Object, default: () => {} },
  },
  computed: {
    ...mapState(['gym']),
    val() {
      return this.gym[this.option.prop]
    },
    isSwitch() {
      return this.option.type == 'switch' || this.option.type == null
    },
    isNumber() {
      return this.option.type == 'number'
    },
  },
  methods: {
    toggleProp() {
      if (!this.isSwitch) return
      this.setProp(!this.val)
    },
    setProp(value) {
      this.gym.$update({ [this.option.prop]: value })
    },
  },
}
</script>
