<template>
  <v-container class="tl-gym-info">
    <v-row>
      <v-col>
        <tl-gym-info-main />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <tl-gym-info-hours />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <tl-gym-info-images />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import gymSettingsPageMixin from '../gym-settings-page.mixin'
import tlAutosaveMixin from '@/services/autosave/tl-auto-save.mixin'
import tlSaveBtnMixin from '@/components/shared/save-button.mixin'
import tlGymInfoMain from './tl-gym-info-main'
import tlGymInfoHours from './tl-gym-info-hours'
import tlGymInfoImages from './tl-gym-info-images'

export default {
  mixins: [toolbarMixin, gymSettingsPageMixin, tlAutosaveMixin, tlSaveBtnMixin],
  components: {
    tlGymInfoMain,
    tlGymInfoHours,
    tlGymInfoImages,
  },
  data: () => ({
    tlToolbarTitle: 'Gym info',
  }),
  computed: {
    ...mapState(['gym']),
    tlToolbarButtons() {
      return [this.tlSaveBtn]
    },
    recordsToSave() {
      return [this.gym]
    },
  },
}
</script>

<style lang="sass" scoped>
.tl-gym-info
  max-width: 800px
</style>
