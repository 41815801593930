<template>
  <v-card>
    <v-card-title>Generic</v-card-title>
    <v-card-text>
      <v-text-field :value="gym.name_short" label="Short name" @input="setProp('name_short', $event)" />
      <v-textarea
        :value="gym.description"
        rows="3"
        label="Description"
        placeholder="Tell clients what you offer: wall surface, disciplines, climb renewals, historic roots of the gym, etc."
        @input="setProp('description', $event)"
      />
      <v-text-field
        :value="gym.parking"
        label="Parking"
        placeholder="Tell your clients about parking facilities, availability and pricing."
        @input="setProp('parking', $event)"
      />
      <v-text-field :value="gym.address" label="Address" @input="setProp('address', $event)" />
      <v-text-field :value="gym.city" label="City" @input="setProp('city', $event)" />
      <v-text-field :value="gym.postal_code" label="Postal code" @input="setProp('postal_code', $event)" />
      <tl-country-select :value="gym.country" class="pb-4" @input="setProp('country', $event)" />
      <v-text-field :value="gym.url_website" label="Website url" @input="setProp('url_website', $event)" />
      <v-text-field :value="gym.url_facebook" label="Facebook url" @input="setProp('url_facebook', $event)" />
      <v-text-field :value="gym.phone_number" label="Phone" @input="setProp('phone_number', $event)" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import tlCountrySelect from '@/components/profile/tl-country-select'

export default {
  components: {
    tlCountrySelect,
  },
  computed: {
    ...mapState(['gym']),
  },
  methods: {
    setProp(prop, value) {
      this.gym.$update({ [prop]: value })
    },
  },
}
</script>
