<template>
  <v-list-item two-line class="white">
    <v-list-item-action>
      <v-btn icon @click="openEditor">
        <tl-grade-circle
          v-if="group.hold"
          size="36"
          :color="group.hold.color"
          :color-secondary="group.hold.color_secondary"
        />
        <v-icon v-else :style="{ color: group.color }" large>tl-label</v-icon>
      </v-btn>
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>
        <v-icon v-if="!group.live" small>tl-shield</v-icon>
        {{ group.name }}
      </v-list-item-title>
      <v-list-item-subtitle>{{ group.description }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn icon @click="openEditor">
        <v-icon>tl-edit</v-icon>
      </v-btn>
    </v-list-item-action>

    <v-list-item-action>
      <v-btn icon :disabled="removing" :loading="removing" @click="remove">
        <v-icon>tl-delete</v-icon>
      </v-btn>
    </v-list-item-action>

    <v-list-item-action class="ml-2" style="cursor: row-resize">
      <v-icon v-handle>tl-drag-vertical</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { ElementMixin, HandleDirective } from 'vue-slicksort'
import Group from '@/models/Group'
import tlGradeCircle from '@/components/gym/climbs/tl-grade-circle'
import tlSetupGroupEdit from './tl-setup-group-edit'

export default {
  mixins: [ElementMixin],
  directives: {
    handle: HandleDirective,
  },
  components: {
    tlGradeCircle,
  },
  props: {
    group: { type: Group, default: () => {} },
  },
  data: () => ({
    removing: false,
  }),
  methods: {
    setProp(prop, newVal) {
      this.group.$update({ [prop]: newVal })
    },
    openEditor() {
      this.$store.dispatch('dialog/open', {
        component: tlSetupGroupEdit,
        props: {
          group: this.group,
          maxWidth: 800,
        },
      })
    },
    async remove() {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        title: `Remove group`,
        text: 'Are you sure?',
      })
      if (!confirmed) return

      this.removing = true
      this.group
        .$apiUpdate({ removed_at: new Date() })
        .then(() => {
          this.$store.dispatch('toast/success', 'Group removed')
        })
        .finally(() => {
          this.removing = false
        })
    },
  },
}
</script>
