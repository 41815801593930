<template>
  <v-card>
    <v-img min-width="280px" height="200px" :src="require('./setters.jpg')" />

    <v-card-title class="d-flex flex-column align-start">
      <div>Setters and admins</div>
      <span class="grey--text subtitle-1">Manage setters and permissions</span>
    </v-card-title>

    <v-card-actions>
      <v-btn text @click="expanded = !expanded">
        {{ expanded ? 'Hide' : 'Edit' }}
      </v-btn>
      <div class="flex-grow-1"></div>
      <v-btn icon @click="expanded = !expanded">
        <v-icon>{{ expanded ? 'tl-chevron-up' : 'tl-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="expanded">
        <v-divider />
        <div class="pa-4 pb-0 grey--text">
          <span v-if="settingSetters.length > 1">
            Use the drag handles to change the order in the setter quick-select dropdown of the climbs editor.
          </span>
          <span v-else>
            Add setters to appear in the quick-select of the climbs editor, and edit their permissions.
          </span>
        </div>
        <v-list id="setters-list">
          <v-subheader v-if="settingSetters.length && nonSettingSetters.length">Setters</v-subheader>
          <slick-list
            v-model="settingSetters"
            lock-axis="y"
            lock-offset="10%"
            use-drag-handle
            lock-to-container-edges
            append-to="#setters-list"
            class="grey lighten-2"
            helper-class="elevation-4"
          >
            <tl-setup-setter
              v-for="(setter, index) in settingSetters"
              :key="setter.id"
              :index="index"
              :setter="setter"
            />
          </slick-list>

          <v-divider v-if="settingSetters.length && nonSettingSetters.length" />
          <v-subheader v-if="nonSettingSetters.length">Non-setters</v-subheader>

          <slick-list
            v-model="nonSettingSetters"
            lock-axis="y"
            lock-offset="10%"
            use-drag-handle
            lock-to-container-edges
            append-to="#setters-list"
            class="grey lighten-2"
            helper-class="elevation-4"
          >
            <tl-setup-setter
              v-for="(setter, index) in nonSettingSetters"
              :key="setter.id"
              :index="index"
              :setter="setter"
            />
          </slick-list>
        </v-list>
        <v-divider />

        <v-card-actions>
          <v-btn text @click="addSetter">Add setter</v-btn>
        </v-card-actions>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { SlickList } from 'vue-slicksort'
import Setter from '@/models/Setter'
import tlSetupSetter from './tl-setup-setter'
import tlSetupSetterEdit from './tl-setup-setter-edit'

export default {
  components: {
    tlSetupSetter,
    SlickList,
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    ...mapState(['gym']),
    setters() {
      return this.gym.setters.filter(setter => setter.removed_at == null)
    },
    settingSetters: {
      get() {
        return this.setters.filter(setter => setter.is_setter).sort((a, b) => a.order - b.order)
      },
      set(newSettersArr) {
        newSettersArr.forEach((setter, index) => setter.$update({ order: index }))
      },
    },
    nonSettingSetters: {
      get() {
        return this.setters.filter(setter => !setter.is_setter).sort((a, b) => a.order - b.order)
      },
      set(newNonSettersArr) {
        newNonSettersArr.forEach((setter, index) => setter.$update({ order: index + 1000 }))
      },
    },
  },
  methods: {
    addSetter() {
      this.$store.dispatch('dialog/open', {
        component: tlSetupSetterEdit,
        props: {
          setter: new Setter({
            gym_id: this.gym.id,
            is_setter: true,
            linked: false,
          }),
          maxWidth: 800,
        },
      })
    },
  },
}
</script>
