export default {
  computed: {
    tlToolbarTabs() {
      return [ // eslint-disable-line
        { name: 'Setup', to: { name: 'gymSetup' } },
        { name: 'Gym Info', to: { name: 'gymInfo' } },
        { name: 'Settings', to: { name: 'gymSettings' } },
      ]
    },
  },
}
