import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('autosave', ['status']),
  },
  beforeRouteLeave(to, from, next) {
    this.setRecordsToSave([]) // Triggers a save if saveable
    next()
  },
  watch: {
    recordsToSave: {
      immediate: true,
      handler(newVal) {
        this.setRecordsToSave(newVal)
      },
    },
  },
  methods: {
    ...mapActions('autosave', ['setRecordsToSave', 'saveNow']),
  },
}
