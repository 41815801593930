<template>
  <v-row>
    <v-col cols="12" sm="7">
      <v-card>
        <v-card-title>Main impression</v-card-title>
        <v-card-text>
          <v-hover>
            <template v-slot:default="{ hover }">
              <div>
                <v-img
                  v-if="mainImpression"
                  :src="`${mainImpression.url}?t=w&w=600`"
                  @click="openImgDialog(mainImpression)"
                >
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute>
                      <v-icon large>tl-zoom-in</v-icon>
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </div>
            </template>
          </v-hover>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="uploadImp(mainImpression, true)">
            {{ gym.srcMainImpression ? 'Change' : 'Upload' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="12" sm="5">
      <v-card>
        <v-card-title>Gallery</v-card-title>

        <v-card-text id="gallery-list">
          <slick-list
            v-model="gallery"
            lock-axis="y"
            lock-offset="10%"
            use-drag-handle
            lock-to-container-edges
            append-to="#gallery-list"
            helper-class="elevation-4"
          >
            <slick-item v-for="(img, index) in gallery" :key="img.id" :index="index">
              <tl-gym-info-image
                :img="img"
                :impressions="impressions"
                :loading-drag="reordering"
                @edit="uploadImp($event)"
              />
            </slick-item>
          </slick-list>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="uploadImp()">Add impression</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { SlickList, SlickItem } from 'vue-slicksort'
import GymResources from '@/models/GymResources'
import tlGymInfoImage from './tl-gym-info-image.vue'

export default {
  components: {
    SlickList,
    SlickItem,
    tlGymInfoImage,
  },
  data: () => ({
    reordering: false,
  }),
  computed: {
    ...mapState(['gym']),
    impressions() {
      return this.gym.gym_resources.slice().sort((a, b) => a.order - b.order)
    },
    mainImpression() {
      return this.gym.gym_resources.find(gr => gr.resource_type == 'main_impression')
    },
    gallery: {
      get() {
        return this.impressions.filter(gr => gr.resource_type != 'main_impression')
      },
      set(newArr) {
        this.reordering = true
        newArr.forEach((img, index) => img.$update({ order: index }))
        this.$store.dispatch('autosave/saveNow').finally(() => {
          this.reordering = false
        })
      },
    },
  },
  created() {
    this.fetchResources()
  },
  watch: {
    'impressions.length'() {
      this.$store.dispatch('autosave/addRecordsToSave', this.gym.gym_resources)
    },
  },
  methods: {
    async fetchResources() {
      await GymResources.$apiAll({ params: { gym_id: this.gym.id } })
    },
    openImgDialog(selected) {
      this.$store.dispatch('dialog/open', {
        component: () => import('@/components/shared/tl-img-dialog.vue'),
        props: { images: this.impressions, selected, maxWidth: 800 },
      })
    },
    async uploadImp(impression, isMain = false) {
      const component = () => import('@/components/shared/tl-img-upload.vue')
      const props = {
        initialImg: impression && impression.url,
        title: `${impression ? 'Edit' : 'Add'} ${isMain ? 'main' : ''} impression`,
        maxWidth: 1000,
        width: 900,
        height: 600,
        folder1: 'gyms',
        folder2: this.gym.slug,
        folder3: 'gallery',
        filename: `${isMain ? 'main_' : ''}impression.png`,
        fullscreen: this.$vuetify.breakpoint.xsOnly,
      }
      let urlUploaded = await this.$store.dispatch('dialog/open', { component, props })
      if (!urlUploaded) return
      if (impression) {
        impression.$update({ url: urlUploaded })
      } else {
        GymResources.$apiCreate({
          gym_id: this.gym.id,
          resource_type: `${isMain ? 'main_' : ''}impression`,
          url: urlUploaded,
        })
      }
    },
  },
}
</script>
