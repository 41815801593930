<template>
  <v-card dark class="mx-auto" max-width="500">
    <v-card-title class="d-flex flex-column align-start">
      <div>Gym Setup admin</div>
      <div class="grey--text subtitle-1">Initial gym configuration</div>
    </v-card-title>

    <v-card-text>
      <v-text-field :value="gym.latitude" label="Latitude" type="number" @change="setProp('latitude', $event)" />
      <v-text-field :value="gym.longitude" label="Longitude" type="number" @change="setProp('longitude', $event)" />
      <v-text-field
        :value="gym.floorplan_version"
        label="Floorplan version"
        type="number"
        @change="setProp('floorplan_version', $event)"
      />
      <v-switch v-model="has_routes" label="Has routes" color="primary" />
      <v-select v-model="live" :items="liveOpts" label="Live" />
      <v-select
        :value="gym.gym_type"
        :items="['climbing', 'boulder']"
        label="Gym type"
        @change="setProp('gym_type', $event)"
      />
      <v-text-field :value="gym.slug" label="Slug" @change="setProp('slug', $event)" />
      <tl-country-select :value="gym.country" label="Country" @input="setProp('country', $event)" />
      {{ gym.country }}
      <v-text-field :value="gym.time_zone" label="Time zone" @change="setProp('time_zone', $event)" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import tlCountrySelect from '@/components/profile/tl-country-select'

export default {
  components: {
    tlCountrySelect,
  },
  data: () => ({
    liveOpts: [
      { text: 'Live', value: true },
      { text: 'Comming soon', value: 'null' },
      { text: 'Not live', value: false },
    ],
  }),
  computed: {
    ...mapState(['gym']),
    has_routes: {
      get() {
        return this.gym.has_routes
      },
      set(newVal) {
        this.setProp('has_routes', newVal)
      },
    },
    live: {
      get() {
        return this.gym.live == null ? 'null' : this.gym.live
      },
      set(newVal) {
        const parsed = newVal == 'null' ? null : newVal
        this.setProp('live', parsed)
      },
    },
  },
  methods: {
    setProp(prop, value) {
      this.gym.$update({ [prop]: value })
    },
  },
}
</script>
