<template>
  <v-card>
    <v-card-title class="headline">{{ isNew ? 'Add' : 'Edit' }} setter or admin</v-card-title>
    <v-divider class="mb-4" />

    <v-card-text>
      <v-text-field v-model="newSetter.name" label="Name" hide-details />

      <v-subheader class="title px-0 mt-4">Permissions</v-subheader>
      <v-list-item
        v-for="permission in permissions"
        :key="permission.key"
        two-line
        :three-line="$vuetify.breakpoint.xsOnly"
        class="px-0"
      >
        <v-list-item-action>
          <v-checkbox v-model="newSetter[permission.key]" color="primary" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="d-flex flex-align-center">
            {{ permission.name }}<v-icon v-if="permission.icon" v-text="permission.icon" small />
          </v-list-item-title>
          <v-list-item-subtitle>{{ permission.description }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-expand-transition>
        <div v-if="emailRequired">
          <p>Admins require an email to identify and link them to their TopLogger account</p>
          <v-text-field type="email" v-model="newSetter.email" label="Email" prepend-icon="tl-email" />
        </div>
      </v-expand-transition>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer />
      <v-btn text @click="close">Cancel</v-btn>
      <v-btn text color="primary" :disabled="saveDisabled" :loading="saving" @click="apply">
        {{ isNew ? 'Add' : 'Update' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import errorService from '@/services/error-service'
import Setter from '@/models/Setter'

export default {
  props: {
    setter: { type: Setter, default: null },
  },
  data: () => ({
    isNew: false,
    saving: false,
    newSetter: {},
    permissions: [
      {
        key: 'is_setter',
        name: 'Setter',
        description: 'Make this user selectable as the creator of a climb in the climbs-editor',
      },
      {
        key: 'linked',
        name: 'Setter admin',
        icon: 'tl-shield',
        description: 'Gives permission to the climbs- and competition editor and communications tab',
      },
      {
        key: 'admin',
        name: 'Gym admin',
        icon: 'tl-shield-check',
        description: 'Authorize full access, including gym settings and statistics',
      },
    ],
  }),
  computed: {
    emailRequired() {
      return this.newSetter.linked || this.newSetter.admin
    },
    saveDisabled() {
      return this.saving || (this.emailRequired && !this.newSetter.email)
    },
    changedProps() {
      return Object.keys(this.newSetter).reduce((changed, prop) => {
        const newVal = this.newSetter[prop]
        const oldVal = this.setter[prop]
        if (newVal != oldVal) changed[prop] = newVal
        return changed
      }, {})
    },
  },
  created() {
    this.isNew = !this.setter.$id
    this.newSetter = Object.assign({}, this.setter)
  },
  watch: {
    'newSetter.admin'(isAdmin) {
      if (isAdmin) this.$set(this.newSetter, 'linked', true)
    },
    'newSetter.linked'(isLinked) {
      if (!isLinked) this.$set(this.newSetter, 'admin', false)
    },
  },
  methods: {
    ...mapActions('dialog', ['close']),
    apply() {
      this.saving = true
      let promise = this.isNew // eslint-disable-line
        ? Setter.$apiCreate(this.newSetter)
        : this.setter.$apiUpdate(this.changedProps)
      promise
        .then(() => {
          this.$store.dispatch('toast/success', `Setter ${this.isNew ? 'added' : 'updated'}`)
          this.close()
        })
        .catch(errorService.toast)
        .finally(() => (this.saving = false))
    },
  },
}
</script>
