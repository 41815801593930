<template>
  <v-list-item class="white">
    <v-list-item-action>
      <v-btn icon @click="editColor">
        <tl-grade-circle size="38" :color="hold.color" :color-secondary="hold.color_secondary" />
      </v-btn>
    </v-list-item-action>

    <v-list-item-content>
      <v-text-field
        placeholder="Name"
        :value="hold.brand"
        single-line
        hide-details
        class="pa-0 ma-0"
        @input="setProp('brand', $event)"
      />
    </v-list-item-content>

    <v-list-item-action>
      <v-btn icon :disabled="removing" :loading="removing" @click="remove">
        <v-icon>tl-delete</v-icon>
      </v-btn>
    </v-list-item-action>

    <v-list-item-action class="ml-2" style="cursor: row-resize">
      <v-icon v-handle>tl-drag-vertical</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { ElementMixin, HandleDirective } from 'vue-slicksort'
import Hold from '@/models/Hold'
import tlGradeCircle from '@/components/gym/climbs/tl-grade-circle'
import tlSetupHoldColor from './tl-setup-hold-color'

export default {
  mixins: [ElementMixin],
  directives: {
    handle: HandleDirective,
  },
  components: {
    tlGradeCircle,
  },
  props: {
    hold: { type: Hold, default: () => {} },
  },
  data: () => ({
    removing: false,
  }),
  methods: {
    setProp(prop, newVal) {
      this.hold.$update({ [prop]: newVal })
    },
    editColor() {
      let onApply = (color, color_secondary) => {
        this.hold.$update({ color, color_secondary })
      }
      let props = {
        color: this.hold.color,
        colorSecondary: this.hold.color_secondary,
        onApply,
        maxWidth: 800,
      }
      this.$store.dispatch('dialog/open', { component: tlSetupHoldColor, props })
    },
    async remove() {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        title: `Remove hold`,
        text: 'Are you sure?',
      })
      if (!confirmed) return

      this.removing = true
      this.hold
        .$apiUpdate({ removed_at: new Date() })
        .then(() => {
          this.hold.$eject()
          this.$store.dispatch('toast/success', 'Hold removed')
        })
        .finally(() => {
          this.removing = false
        })
    },
  },
}
</script>
