<template>
  <v-list-item class="white">
    <v-list-item-content class="d-flex flex-row flex-no-wrap">
      <v-row>
        <v-col>
          <v-text-field
            placeholder="Name"
            :value="wall.name"
            single-line
            hide-details
            class="pa-0 ma-0"
            @input="setProp('name', $event)"
          />
        </v-col>
        <v-col v-if="user.admin" cols="4">
          <v-text-field
            placeholder="Floorplan id"
            :value="wall.floorplan_id"
            single-line
            hide-details
            class="pa-0 ma-0"
            @input="setProp('floorplan_id', $event)"
          />
        </v-col>
      </v-row>
    </v-list-item-content>

    <v-list-item-action v-if="user.admin" class="ml-2">
      <v-btn icon :disabled="removing" :loading="removing" @click="remove">
        <v-icon>tl-delete</v-icon>
      </v-btn>
    </v-list-item-action>

    <v-list-item-action class="ml-2" style="cursor: row-resize">
      <v-icon v-handle>tl-drag-vertical</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapState } from 'vuex'
import { ElementMixin, HandleDirective } from 'vue-slicksort'
import Wall from '@/models/Wall'

export default {
  mixins: [ElementMixin],
  directives: {
    handle: HandleDirective,
  },
  props: {
    wall: { type: Wall, default: () => {} },
  },
  data: () => ({
    removing: false,
  }),
  computed: {
    ...mapState(['user']),
  },
  methods: {
    setProp(prop, newVal) {
      this.wall.$update({ [prop]: newVal })
    },
    remove() {
      this.removing = true
      this.wall
        .$apiUpdate({ removed_at: new Date() })
        .then(() => {
          this.$store.dispatch('toast/success', 'Wall removed')
        })
        .finally(() => {
          this.removing = false
        })
    },
  },
}
</script>
