<template>
  <v-row align="stretch" class="flex-nowrap">
    <v-col>
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-img :src="`${img.url}?t=w&w=600`" aspect-ratio="1.5" class="grey lighten-2" @click="openImgDialog">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
            <v-fade-transition>
              <v-overlay v-if="hover" absolute>
                <v-icon large>tl-zoom-in</v-icon>
              </v-overlay>
            </v-fade-transition>
          </v-img>
        </template>
      </v-hover>
    </v-col>
    <v-col class="pl-0 d-flex flex-column justify-space-between" style="flex-grow: 0">
      <v-btn icon @click="$emit('edit', img)">
        <v-icon>tl-edit</v-icon>
      </v-btn>
      <v-btn icon :disabled="loadingDrag" :loading="loadingDrag" v-handle style="cursor: row-resize">
        <v-icon>tl-drag-vertical</v-icon>
      </v-btn>
      <v-btn icon :disabled="removing" :loading="removing" @click="remove">
        <v-icon>tl-delete</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { HandleDirective } from 'vue-slicksort'
import GymResources from '@/models/GymResources'

export default {
  directives: {
    handle: HandleDirective,
  },
  props: {
    img: { type: GymResources, default: () => {} },
    impressions: { type: Array, default: () => [] },
    loadingDrag: { type: Boolean, default: false },
  },
  data: () => ({
    removing: false,
  }),
  methods: {
    openImgDialog() {
      const component = () => import('@/components/shared/tl-img-dialog.vue')
      this.$store.dispatch('dialog/open', {
        component,
        props: {
          images: this.impressions,
          selected: this.img,
          maxWidth: 800,
        },
      })
    },
    remove() {
      this.removing = true
      this.img.$apiDestroy().finally(() => {
        this.removing = false
      })
    },
  },
}
</script>
