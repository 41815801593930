<template>
  <v-container>
    <v-card class="mx-auto" max-width="500">
      <v-card-title class="d-flex flex-column align-start">
        <div>Settings</div>
        <div class="grey--text subtitle-1">Customize the gym to your needs</div>
      </v-card-title>
      <v-list two-line subheader>
        <v-divider />
        <v-subheader>Climbs</v-subheader>
        <tl-gym-setting v-for="option in optionsClimbs" :key="option.prop" :option="option" />

        <v-divider />
        <v-subheader>Logging</v-subheader>
        <tl-gym-setting v-for="option in optionsLogging" :key="option.prop" :option="option" />

        <v-divider />
        <v-subheader>Enable input fields in the climbs editor</v-subheader>
        <tl-gym-setting v-for="option in optionsEditor" :key="option.prop" :option="option" />
        <v-expand-transition>
          <v-combobox
            v-if="gym.edit_climb_show_remarks"
            v-model="remarks"
            :items="remarksOpts"
            label="Remarks quick-buttons"
            hint="Start typing to add custom tags"
            multiple
            chips
            class="ml-8 pa-4"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Press <kbd>enter</kbd> to add your custom tag</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </v-expand-transition>

        <v-divider />
        <v-subheader>Grading system{{ gym.gym_type == 'climbing' ? 's' : '' }}</v-subheader>

        <v-list-item @click="showGradesChartDialog()">
          <v-list-item-content>Show comparison chart</v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon>tl-arrow-forward</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item v-if="gym.gym_type == 'climbing'">
          <v-list-item-avatar>
            <v-icon class="grey lighten-1 white--text">tl-check-route</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <tl-grade-system-selector
              :value="gym.grading_system_routes"
              climb-type="route"
              prepend-icon
              custom-editable
              @input="setGradingSystem('route', $event)"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-avatar>
            <v-icon class="grey lighten-1 white--text">tl-check-boulder</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <tl-grade-system-selector
              :value="gym.grading_system_boulders"
              climb-type="boulder"
              prepend-icon
              custom-editable
              @input="setGradingSystem('boulder', $event)"
            />
          </v-list-item-content>
        </v-list-item>

        <v-divider />
        <v-subheader>Miscelaneous</v-subheader>

        <v-list-item @click="toggleAutoGrade">
          <v-list-item-avatar>
            <v-icon class="grey lighten-1 white--text">tl-equalizer</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Community auto grading</v-list-item-title>
            <v-list-item-subtitle>Auto-adjust grade according to community votes</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-switch v-model="gym.auto_grade" color="primary" @click.prevent="" />
          </v-list-item-action>
        </v-list-item>
        <v-expand-transition>
          <tl-gym-setting v-if="gym.auto_grade" class="ml-12 pl-12" :option="optionAutoGradeVotes" />
        </v-expand-transition>

        <tl-gym-setting :option="optionReservationsEnabled" />

        <v-list-item>
          <v-list-item-avatar>
            <v-icon class="grey lighten-1 white--text">tl-exit-to-app</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Demo user logout password</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn text @click="changeDemoPwd">Change</v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <tl-gym-settings-admin v-if="user.admin" class="mt-12" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import sha1 from 'simple-sha1'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import gymSettingsPageMixin from '../gym-settings-page.mixin'
import tlAutosaveMixin from '@/services/autosave/tl-auto-save.mixin'
import tlSaveBtnMixin from '@/components/shared/save-button.mixin'
import tlGymSetting from './tl-gym-setting'
import tlGymSettingsAdmin from './tl-gym-settings-admin'
import tlGradeSystemSelector from '@/services/grades/tl-grade-system-selector'
import tlGradesChartDialogMixin from '@/components/shared/tl-grades-chart/tl-grades-chart.dialog.mixin'

export default {
  mixins: [ // eslint-disable-line
    toolbarMixin,
    gymSettingsPageMixin,
    tlAutosaveMixin,
    tlSaveBtnMixin,
    tlGradesChartDialogMixin,
  ],
  components: {
    tlGymSetting,
    tlGymSettingsAdmin,
    tlGradeSystemSelector,
  },
  data: () => ({
    tlToolbarTitle: 'Gym settings',
    optionsClimbs: [
      {
        icon: 'tl-assignment-ind',
        title: 'Show setter',
        subtitle: 'Enable users to see the setter name',
        prop: 'show_setter',
      },
      {
        icon: 'tl-new-box',
        title: 'New label',
        subtitle: 'Days to mark climb as new',
        type: 'number',
        prop: 'label_new_days',
      },
      {
        icon: 'tl-new-releases',
        title: 'Almost removed label',
        subtitle: 'Days to mark climb as almost removed',
        type: 'number',
        prop: 'label_removed_days',
      },
    ],
    optionsLogging: [
      {
        icon: 'tl-autorenew',
        title: 'Vote for renewal',
        subtitle: 'Show button for users to vote for renewal',
        prop: 'vote_renewal',
      },
      {
        icon: 'tl-feedback',
        title: 'Report climb',
        subtitle: 'Show button for users to report a climb',
        prop: 'report_btn',
      },
    ],
    remarksOpts: ['Sit start', 'Jump start', 'Without arete'],
    optionAutoGradeVotes: {
      type: 'number',
      prop: 'auto_grade_stable_votes',
      title: 'Votes to mark stable grade',
      subtitle: 'Number of community votes required to mark climb grade as stable',
    },
    optionReservationsEnabled: {
      icon: 'tl-calendar-clock',
      title: 'Enable reservations system',
      subtitle: 'Allow users to reserve your time slots',
      prop: 'reservations_enabled',
    },
  }),
  computed: {
    ...mapState(['user', 'gym']),
    tlToolbarButtons() {
      return [this.tlSaveBtn]
    },
    optionsEditor() {
      let opts = [
        { title: 'Number', prop: 'edit_climb_show_number' },
        { title: 'Rope number', prop: 'rope_numbers', hide: this.gym.gym_type == 'boulder' },
        { title: 'Name', prop: 'edit_climb_show_name' },
        { title: 'Setter', prop: 'edit_climb_show_setter' },
        { title: 'Expected removal', prop: 'edit_climb_show_expected_removal_at' },
        { title: 'Mark to renew', prop: 'renew' },
        { title: 'Suitable for children', prop: 'show_for_kids' },
        { title: 'Zones', prop: 'show_zones' },
        {
          title: 'Grade stability',
          subtitle: 'Buttons to indicate likeliness of future grade changes',
          prop: 'show_grade_stability_admin',
        },
        { title: 'Remarks', prop: 'edit_climb_show_remarks' },
      ]
      return opts.filter(o => !o.hide)
    },
    recordsToSave() {
      return [this.gym]
    },
    remarks: {
      get() {
        if (!this.gym.remarks_quick_add) return []
        let res = this.gym.remarks_quick_add.split(';')
        return res
      },
      set(newArr) {
        this.setGymProp('remarks_quick_add', newArr.join(';'))
      },
    },
  },
  methods: {
    setGymProp(prop, value) {
      this.gym.$update({ [prop]: value })
    },
    setGradingSystem(type, systemName) {
      this.setGymProp(`grading_system_${type}s`, systemName)
      this.$store.dispatch('updateGradeSystems')
    },
    async toggleAutoGrade() {
      if (!this.gym.auto_grade) {
        this.setGymProp('auto_grade', true)
      } else {
        let confirmed = await this.$store.dispatch('dialog/confirm', {
          title: 'Are you sure?',
          text: 'This will disable auto-grading on all climbs where this is currently enabled.',
        })
        if (confirmed) this.setGymProp('auto_grade', false)
      }
    },
    async changeDemoPwd() {
      const isDefaultPwd = this.gym.local_device_pwd == sha1.sync('toplogger')
      let text = 'Set the password to unlock the fixed devices in your gym'
      if (isDefaultPwd) text += " (Your current password is: 'toplogger')"
      const props = {
        title: 'Change password for demo user',
        text,
        type: 'password',
        label: 'Password',
        ok: 'Set password',
        onSave: pwd => {
          this.gym.$apiUpdate({ local_device_pwd: sha1.sync(pwd) }).then(() => {
            this.$store.dispatch('toast/success', 'Your fixed devices logout password has been updated')
          })
        },
      }
      this.$store.dispatch('dialog/prompt', props)
    },
  },
}
</script>
